.videoPlayer {
    pointer-events: none;
    height: auto;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

@media only screen and (max-width: 1050px) {
    .videoPlayer {
        width: 120vw;
        margin-top: 5vh;
        margin-left: -5vw;
    }

}

@media only screen and (max-width: 800px) {
    .videoPlayer {
        width: 120vw;
        margin-top: 10vh;
        margin-left: -5vw;
    }

}

@media only screen and (max-width: 400px) {
    .videoPlayer {
        width: 110vw;
        margin-top: 15vh;
        margin-left: -5vw;
      }
    
}