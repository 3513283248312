.daysContent {
    margin-left: 2.5em;
}

.mobile-col {
    margin-left: 0;
}

@media all and (max-width: 503px) {
    .mobile-col {
        width: flex-end;
        margin-left: 2.5em;
    }

    
}